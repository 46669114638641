<template>
    <div style="margin-top: 38px;">
        <div class="about">
            <div class="about__center center">
                <h2 class="about__subtitle h2 aos-init aos-animate" data-aos="animation-scale-top" data-aos-duration="600">Cookie Policy</h2>
    
            </div>
            <vs-row style="width: 100%; gap: 30px;" justify="center">
                <vs-row direction="column" style="width:80%;">
                    <h2 class="stage" style="font-size:16px;">PREMESSA</h2>
                    <p style="text-align: left;">La presente cookie policy è resa per il sito www.remindmevision.it. Il documento è stato redatto tenendo conto di quanto indicato dal Regolamento europeo 679/2016 in materia di protezione dei dati personali (GDPR), dal Codice della Privacy (D. Lgs. 30 giugno 2003 n. 196) e delle Linee Guida del Garante Privacy (sopratutto le Linee Guida sull'uso dei cookie emesse il 10 luglio 2021).
                    </p>
                </vs-row>
                <vs-row direction="column" style="width:80%;">
                    <h2 class="stage" style="font-size:16px;">Titolare del Trattamento:</h2>
                    <p style="text-align: left;">
                        RemindMe S.r.l., con sede legale in Corso giacomo Matteotti 42 10121 Torino (TO) e sede operativa in Strada del Mondino 13/D Castiglione Torinese (TO) 10090, P.I. 12783780013– R.E.A. Numero TO – 1316058
                    </p>
                </vs-row>
                <vs-row direction="column" style="width:80%;">
                    <h1 class="stage" style="font-size:22px;">INFORMAZIONI GENERALI</h1>
                    <h2 class="stage" style="font-size:16px;">Che cosa sono i cookie e a cosa servono</h2>
                    
                    <p style="text-align: left;">
                        Un cookie è un file di testo che un sito web visitato dall’utente invia al suo terminale (computer, dispositivo mobile quale smartphone o tablet), dove viene memorizzato per essere poi ritrasmesso a tale sito in occasione di una visita successiva al sito medesimo.

I cookie vengono tra loro distinti:

in base al soggetto che li installa, a seconda che si tratti dello stesso gestore del sito visitato (c.d. "cookie di prima parte") ovvero di un soggetto diverso (c.d. "cookie di terza parte");
in base alla finalità di ciascun cookie: alcuni cookie permettono una migliore navigazione, memorizzando alcune scelte dell'utente, ad esempio la lingua (c.d. "cookie tecnici"), altri cookie consentono di monitorare la navigazione dell'utente anche allo scopo di inviare pubblicità e/od offrire servizi in linea con sue preferenze (c.d. "cookie di profilazione").
Solo i cookie di profilazione richiedono il consenso preventivo dell'utente al loro utilizzo.

Il Titolare del Trattamento è responsabile esclusivamente dei cookie di prima parte dallo stesso installati sul Sito.

Alla sezione "Cookie tecnici di prima parte" Lei può visionare le tipologie di cookie tecnici di prima parte rilasciati dal Sito.

La gestione dei cookie di profilazione è descritta alla sezione "Cookie di profilazione di terza parte".

Ad ogni modo, Lei può abilitare/disabilitare i cookie anche attraverso le opzioni del Suo browser:
                    </p>
                </vs-row>
                <vs-row direction="column" style="width:80%;">
                    <h2 class="stage" style="font-size:16px;">Internet Explorer</h2>
                    <p style="text-align: left;">
                        Accedere al menu Strumenti, quindi a Opzioni Internet.

Cliccare su Privacy, quindi su Avanzate.

Nella finestra Cookie, selezionare le proprie preferenze.


                    </p>
                </vs-row>
                <vs-row direction="column" style="width:80%;">
                    <h2 class="stage" style="font-size:16px;">Google Chrome</h2>
                    <p style="text-align: left;">
                        Cliccare sul menu di Chrome, corrispondente al pulsante in alto a destra.

Selezionare Impostazioni, quindi cliccare su Avanzate.

Nella sezione Privacy e sicurezza, cliccare sul pulsante Impostazioni contenuti.

Selezionare le opzioni preferite nella sezione Cookie.


                    </p>
                </vs-row>
                <vs-row direction="column" style="width:80%;">
                    <h2 class="stage" style="font-size:16px;">Firefox</h2>
                    <p style="text-align: left;">
                        Cliccare su Strumenti, quindi sul menu Opzioni.

Cliccare sulle impostazioni Privacy e sicurezza.

Selezionare Utilizza impostazioni personalizzate per la cronologia.

Selezionare le opzioni preferite nella sezione Accetta cookie e dati dai siti web.


                    </p>
                </vs-row>
                <vs-row direction="column" style="width:80%;">
                    <h2 class="stage" style="font-size:16px;">Safari</h2>
                    <p style="text-align: left;">
                        Cliccare su Safari, quindi su Preferenze.

Cliccare sulla sezione Privacy e sicurezza.

Andare su Blocca cookie e selezionare le opzioni preferite.


    
                    </p>
                </vs-row>
                <vs-row direction="column" style="width:80%;">
                    <h2 class="stage" style="font-size:16px;">Cookie di prima parte e tecnici</h2>
                    <p style="text-align: left;">
                        Il Sito utilizza cookie di prima parte e tecnici necessari per il suo corretto funzionamento. Non è possibile negare il rilascio di questi cookie.
    
                    </p>
                </vs-row>
                <vs-row direction="column" style="width:80%;">
                    <h2 class="stage" style="font-size:16px;">I Suoi diritti</h2>
                    <p style="text-align: left;">
                        Ai sensi dell’art. 13 del GDPR, il Titolare del Trattamento La informa che Lei ha diritto di:
<ul>
<li>chiedere al Titolare del Trattamento l’accesso ai Suoi dati personali e la rettifica o la cancellazione degli stessi o la limitazione del trattamento che La riguardano o di opporsi al loro trattamento, oltre al diritto alla portabilità dei dati</li>
<li>revocare il consenso in qualsiasi momento senza pregiudicare la liceità del trattamento basata sul consenso prestato prima della revoca</li>
<li>proporre reclamo a un’autorità di controllo (es.: il Garante per la protezione dei dati personali).</li><br>
</ul>
I diritti di cui sopra potranno essere esercitati con richiesta rivolta senza formalità ai contatti indicati in Premessa.

                    </p>
                </vs-row>
                
            </vs-row>
        </div>
    </div>
    </template>
    
    <script>
    import AOS from 'aos';
    export default ({
        mounted() {
            AOS.init();
        },
    
    })
    </script>
    
    <style scoped>
    ul {
        list-style-type:circle;
        margin-left: 19px;
        margin-top: 10px;
    }
    </style>